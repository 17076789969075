
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

:root {
    --main-text-color: black;
    --sec-text-color: rgb(186, 21, 21);
    --main-bg-color: white;
}
* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: "Josefin Sans", sans-serif;
    user-select: none;
}
.flex {
	display: -webkit-flex;
	display: flex;
	justify-content: space-between;
}
.slide-in {
    animation: slide-in 1.2s ease-in-out;
    animation-fill-mode: forwards;
    @keyframes slide-in {
        0% {
            transform: translateY(-100%);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }
}
#menuopen {
    display: none;
}
#mobile-nav-bar {
    display: none;
}
body {
    position: relative;
}

// HEADER
header {
    width: 100%;
    padding: 15px 20px;
    margin: 0px auto 0px auto;
    background-color: var(--main-bg-color);
    box-shadow: 0px 0px 20px 0px;
    position: fixed;
    top: 0px;
    z-index: 1;
    div {
        display: flex;
        justify-content: space-between;
        article:first-child {
            a {
                text-decoration: none;
                h1 {
                    font-size: 2.2rem;
                    padding-right: 50px;
                    color: var(--main-text-color);
                    strong {
                        color: var(--sec-text-color);
                    }
                }
            }
            nav ul {
                display: flex;
                list-style-type: none;
                margin-top: 12px;
                li {
                    padding: 0px 40px 0px 0px;
                    a {
                        text-decoration: none;
                        font-size: 1.2rem;
                        color: var(--main-text-color);
                        img {
                            padding-left: 5px;
                            width: 20px;
                        }
                    }
                    a:hover {
                        border-bottom: 2px solid var(--main-text-color);
                        color: var(--sec-text-color);
                    }
                }
            }
        }
        article:last-child {
            display: flex;
            p {
                // img {
                // }
                img:hover {
                    cursor: pointer;
                }
            }
            button{
                margin: 0px 0px 0px 30px;
                background-color: var(--main-bg-color);
                padding: 0px 20px;
                border-radius: 15px;
                border: 2px solid var(--main-text-color);
                height: 35px;
                font-size: 0.9rem;
            }
            button:hover {
                background-color: var(--sec-text-color);
                color: var(--main-bg-color);
                border: 2px solid var(--main-text-color);
            }
        }
    }
}
#contact-dropdown {
    p {
        font-size: 1.2rem;
        color: var(--main-text-color);
    }
    p:hover {
        border-bottom: 2px solid var(--main-text-color);
        color: var(--sec-text-color);
        cursor: pointer;
    }
    article {
      position: absolute;
      margin: 10px 0px 0px -15px;
      display: block;
      padding: 15px 20px;
      z-index: 1;
      background-color: var(--main-bg-color);
      border: 1px solid var(--main-text-color);
      border-radius: 10px;
      a {
        display: block;
        font-weight: 500;
        font-size: 1rem;
        border: none;
        padding: 5px 7px;
        align-items: center;
        color: var(--main-text-color);
        img {
            width: 35px;
            padding-right: 10px;
        }
      }
      a:hover {
        text-decoration: underline;
      }
    }
}
p#screen-mode {
    img {
        width: 22px;
        margin-top: 8px;
    }
}
#form {
    position: absolute;
    background-color: rgba(186, 21, 21, 80%);
    width: 100%;
    margin: 15px auto 0px -18px;
    padding: 20px 30px;
    z-index: 2;
    overflow-y: auto;
    height: 450px;
    div {
        padding: 20px 0px;
        display: block;
        text-align: center;
        label {
            font-size: 1.2rem;
            font-weight: 900;
            text-decoration: underline;
        }
        input {
            width: 85%;
            height: 40px;
            font-size: 1rem;
            border: none;
            text-align: center;
        }
    }
}
#form::-webkit-scrollbar {
    width: 7px;
    background-color: rgba(186, 21, 21, 80%);
    border-radius: 7px;
}
#form::-webkit-scrollbar-thumb {
    background-color: aliceblue;
    border-radius: 7px;
}

// MAIN
#main-big-div {
    width: 100%;
    padding: 7px 0px 0px 0px;
}
main {
    width: 85%;
    margin: 150px auto 0px auto; 
    padding-bottom: 60px;
    // height: 150vh;
    div {
        // // width: 100%;
        // .marquee-container {
        //     overflow: hidden;
        //     position: absolute;
        //     top: -200px;
        //     .marquee-content {
        //         white-space: nowrap;
        //         animation: marquee 10s linear infinite;
        //         h5 {
        //             font-size: 1rem;
        //         }
        //     }
        // } 
        // @keyframes marquee {
        //     0% {
        //       transform: translateX(100%);
        //     }
        //     100% {
        //       transform: translateX(-100%);
        //     }
        // }
        article {
            margin-top: 50px;
            width: 55%;
            h1 {
                font-size: 5.8rem;
                margin-bottom: 40px;
                span {
                    color: var(--sec-text-color);
                    text-decoration: underline;
                }
            }
            p {
                font-size: 1.1rem;
                max-width: 85%;
                margin-bottom: 20px;
                strong {
                    color: var(--sec-text-color);
                }
            }
            marquee {
                 font-size: 1.1rem;
            }
            a {
                text-decoration: none;
                border: 2px solid var(--sec-text-color);
                padding: 7px 15px;
                color: black;
            }
            // a:hover {
            //     color: var(--sec-text-color);
            //     border: 2px solid black;
            // }
        }
        img {
            width: 40%;
            object-fit: cover;
            border-radius: 30px;
            border: 1.5px solid var(--main-text-color);
            box-shadow: 0px 0px 50px 0px;
        }
    }
}

//SKILLS-PAGE
skill {
    div:first-child {
        width: 75%;
        margin: 50px auto;
        margin-top: 50px;
        padding-top: 100px;
        article {
            margin-top: 35px;
            img {
                width: 90px;
                margin-bottom: 25px;
            }
            h2 {
                font-size: 1.6rem;
                margin-bottom: 10px;
            }
            p {
                max-width: 90%;
                font-size: 0.95rem;
                line-height: 20px;
            }
        }
        h1 {
            width: 60%;
            font-size: 5rem;
            strong {
                color: var(--sec-text-color);
            }
        }
    }
    div:nth-child(2) {
        margin: 90px auto 0px auto;
        width: 90%; 
        h1 {
            text-align: center;
            padding-bottom: 20px;
        }
        div {
            margin: 10px auto;
            flex-wrap: wrap;
            article {
                border: 1px solid var(--main-text-color);
                width: 30%;
                padding: 25px;
                border-radius: 20px;
                margin-bottom: 50px;
                img {
                    width: 50px;
                    padding: 0px 10px 10px 0px;
                }
                h1 {
                    font-size: 1.5rem;
                    color: var(--sec-text-color);
                }
                p {
                    line-height: 20px;
                }
            }
            article:hover {
                background-color: var(--main-text-color);
                border: 1px solid var(--main-bg-color);
                h1 {
                    color: var(--sec-text-color);
                }
                p {
                    color: var(--main-bg-color);
                }
            }
        }
    }
    div:nth-child(3) {
        text-align: center;
        h1 {
            margin-bottom: 20px;
            strong {
                color: var(--sec-text-color);
            }
        }
        img {
            width: 80px;
            margin: 0px 30px 30px 30px;
        }
    }
}

//PROJECT-PAGE
project {
    #big-div {
        // padding-top: 0px;
    }
    div {
        width: 95%;
        flex-wrap: wrap;
        text-align: center;
        margin: auto;
        padding: 50px 0px 30px 0px;
        h1:nth-child(1) {
            margin-top: 70px;
            strong {
                color: var(--sec-text-color);
            }
        }
        
        h1#last {
            margin: 20px 0px -30px 0px;
                // color: var(--sec-text-color);
            strong {
                color: var(--sec-text-color);
                // color: var(--main-text-color);
            }
        }
        div {
            div {
                width: 50%;
                padding: 10px 10px;
                img {
                    width: 100%;
                    border-radius: 20px;
                    border: 2px solid var(--sec-text-color);
                    opacity: 0.7;
                }
                img:hover {
                    opacity: 100%;
                }

            }
        }
    }
}

//FOOTER
footer {
    width: 100%;
    margin: 0px auto 20px auto;
    // padding-bottom: 20px;
    div {
        text-align: center;
        h4 {
            width: 100%;
            font-size: 1.5rem;
            background-color: var(--main-bg-color);
            box-shadow: 0px 0px 20px 0px;
            padding: 11px 0px;
            // position: absol;
            // bottom: 45px;
            strong {
                color: var(--sec-text-color);
            }
        }
        article {
            margin-top: 25px;
            a {
                padding: 0px 10px;
                img {
                    width: 25px;
                    // transition: transform 0.5s;
                }
            }
            a:hover {
                img {
                    // width: 30px;
                    // transition: transform 6s;
                }
                border-bottom: 1px solid var(--main-text-color);
            }
        }
    }
}

@media screen and (max-width: 1115px) {

//MAIN-PAGE//
    main {
        div {
            img {
                margin-top: 70px;
            }
        }
    }

//SKILLS-PAGE//
    skill {
        div:first-child {
            h1 {
                margin-top: 35px;
            }
        }
    }
}

@media screen and (max-width: 1100px) {

//MAIN
    main {
        div {
            .marquee-container {
                top: -160px;
            }
            article {
                margin-top: 130px;
            }
            img {
                margin-top: 50px;
                width: 50%;
            }
        }
    }

//SKILLS-PAGE//
    skill {
        div:first-child {
            h1 {
                margin-top: 60px;
            }
        }
    }
} 

@media screen and (max-width: 900px) {

#menuopen {
    display: inline-block;
    width: 30px;
    height: 25px;
    margin: 5px 0px 0px 20px;
}
#mobile-nav-bar.open {
    display: block;
}
#mobile-nav-bar.close {
    display: none;
}

//MAIN-PAGE//
    //HEADER
        header {
            div {
                article:first-child {
                    nav ul {
                        display: none;
                    }
                }
            }
            div#mobile-nav-bar {
                background-color: var(--main-bg-color);
                display: inline-block;
                position: absolute;
                top: 0px;
                right: 0px;
                width: 100%;
                padding: 50px 0px 10px 20px;
                box-shadow: 0px 0px 10px 0px;
                animation: fade-in 0.6s ease-in-out;
                z-index: 2;
                // height: 100%;
                line-height: 35px;
                @keyframes fade-in {
                    0% {
                        transform: translateY(0%);
                        opacity: 0;
                    }
                }
                img#menuClose {
                    position: absolute;
                    right:40px;
                    top: 10px;
                }
                nav ul {
                    li {
                        list-style-type: none;
                        padding: 7px 0px;
                        font-size: 1.2rem;
                        a {
                            text-decoration: none;
                            color: var(--main-text-color);
                        }
                        article {
                                display: inline-block;
                                // margin-top: 10px;
                            a {
                                display: inline-block;
                                padding: 10px 0px 4px 30px;
                                font-size: 1.04rem;
                                img {
                                    width: 20px;
                                    padding-right: 4px;
                                }
                            }
                            a:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }

    //MAIN
        main { 
            // margin: 150px auto 20px auto;
            div {
                // padding-bottom: 70px;
                img {
                    margin-top: 70px;
                }
            }
        }

//SKILLS-PAGE//
    skill {
        div:first-child {
            display: flex;
            flex-direction: column;
            width: 80%;
            margin-top: 50px;
            padding-top: 50px;
            article {
                margin: 25px 0px 30px 0px;
                img {
                    width: 100px;
                    margin-bottom: 25px;
                }
                h2 {
                    font-size: 1.6rem;
                    margin-bottom: 10px;
                }
                p {
                    max-width: 90%;
                    font-size: 1rem;
                    line-height: 20px;
                }
            }
            h1 {
                width: 100%;
                font-size: 4rem;
                margin: 0px 0px -40px 0px;
            }
        }
        div:nth-child(2) {
            width: 95%; 
            div {
                    article {
                        width: 46%;
                    }
    
            }
        }
        div:nth-child(3) {
            h1 {
                font-size: 1.6rem;
                margin-bottom: 20px;
            }
            article {
                img {
                    width: 70px;
                    margin: 0px 10px;
                }
            }
        }
    }

//FOOTER
    footer {
        margin: 0px auto 10px auto;
        div {
            h4 {
                font-size: 1.4rem;
            }
            article {
                margin-top: 20px;
                a {
                    padding: 0px 7px;
                    img {
                        width: 20px;
                    }
                }
                a:hover {
                    border-bottom: 1px solid var(--main-text-color);
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {

    //MAIN-PAGE//
        main {
            width: 80%;
            div {
                flex-direction: column;
                article {
                    width: 100%;
                    margin-top: -30px;
                    h1 {
                    }
                    p {
                        max-width: 100%;
                    }
                }
                img {
                    width: 100%;
                }
            }
        }

}

@media screen and (max-width: 700px) {

//MAIN-PAGE//
    main {
        width: 80%;
        div {
            .marquee-container {
                top: -110px;
            }
            article {
                // margin-top: 80px;
                p {
                    max-width: 100%;
                }
            }
            img {
                width: 100%;
            }
        }
    }

//SKILLS-PAGE//
    skill {
        div:first-child {
            h1 {
                font-size: 2.6rem;
            }
        }
        div:nth-child(2) {
            h1 {
                font-size: 1.4rem;
            }
        }
        div:nth-child(3) {
            h1 {
                font-size: 1.35rem;
                margin-bottom: 20px;
            }
            article {
                img {
                    width: 60px;
                    margin: 0px 10px;
                }
            }
        }
    }

//PROJECT-PAGE
    project {
        div {
            flex-direction: column;
            div {
                div {
                    width: 100%;
                    padding: 10px 10px;
                    img {
                        width: 100%;
                        border-radius: 20px;
                        border: 2px solid var(--sec-text-color);
                        opacity: 0.7;
                    }
                    img:hover {
                        opacity: 100%;
                    }

                }
            }
        }
    }

//FOOTER
    footer {
        margin: 0px auto 10px auto;
        div {
            h4 {
                font-size: 1.2rem;
            }
        }
    }
}

@media screen and (max-width: 500px) {

//HEADER
    header {
        div {
            article:first-child {
                a {
                    h1 {
                        font-size: 2rem;
                        padding-right: 50px;
                        margin-top: 30px;
                        color: var(--main-text-color);
                    }
                }
            }
            article:last-child {
                    flex-direction: column;
                p {
                    img {
                        margin: 0px 0px 5px 0px;
                        width: 25px;
                    }
                }
                button{
                    margin: 0px 0px 5px -20px;
                    font-size: 0.8rem;
                    height: 30px;
                    padding: 0px 18px;
                }
            }
        }
        #menuopen {
            width: 25px;
            height: 20px;
            margin: 5px 0px 0px 0px;
        }
    }

//MAIN
    main {
        margin-top: 200px;
        div {
            article {
                h1 {
                    font-size: 4.5rem;
                    margin-bottom: 20px;
                }
                p {
                    font-size: 1.1rem;
                }
            }
            img {
                margin-top: 25px;
            }
        }
    }

//SKILLS-PAGE//
    skill {
        div:first-child {
            margin-top: 120px;
        }
        div:nth-child(2) {
            width: 100%; 
            div {
                div {
                    article {
                        width: 100%;
                    }
                }
            }
        }
        div:nth-child(2) {
            width: 90%; 
            div {
                article {
                    width: 100%;
                }
            }
        }
        div:nth-child(3) {
            h1 {
                font-size: 1.1rem;
                margin-bottom: 20px;
            }
            article {
                img {
                    width: 40px;
                    margin: 0px 10px;
                }
            }
        }
    }

    //PROJECT-PAGE
    project {
        div {
            width: 95%;
            // flex-wrap: wrap;
            // text-align: center;
            // margin: 30px auto;
            h1:first-child {
                // text-align: center;
                margin-top: 130px;
            }
            div {
                width: 100%;
                // padding: 10px 10px;
                // img {
                //     width: 100%;
                //     border-radius: 20px;
                //     border: 2px solid var(--sec-text-color);
                //     opacity: 0.7;
                // }
                // img:hover {
                //     opacity: 100%;
                // }
            }
        }
    }

    //FOOTER
        footer {
            margin: 0px auto 10px auto;
            div {
                h4 {
                    font-size: 1rem;
                }
                article {
                    margin-top: 20px;
                    a {
                        padding: 0px 7px;
                        img {
                            width: 20px;
                        }
                    }
                    a:hover {
                        border-bottom: 1px solid var(--main-text-color);
                    }
                }
            }
        }
    }